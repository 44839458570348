import Vue from 'vue'
import VueMixpanel from 'vue-mixpanel'
import LogRocket from 'logrocket'

import App from './App.vue'
import i18n from '@/plugins/i18n'
import router from './router'
import store from './store'
import axios from 'axios'
import { AuthPlugin, getInstance } from "./auth"
import titleMixin from './mixins/titleMixin'
import localeMixin from './mixins/localeMixin'

import '@/assets/css/tailwind.css'
import './assets/tailwind.css'
import '@/assets/css/fonts.scss'
import '@/assets/css/theme.scss'

LogRocket.init('uyghdy/tm-self-service', {
  network: {
    requestSanitizer: request => {
      if (request.method === "PUT" && request.url.toLowerCase().indexOf('/api/contact/info') !== -1) {
        request.body = null;
      }
      return request;
    },
    responseSanitizer: response => {
      if (response.url.toLowerCase().indexOf('/api/contact/info') !== -1) {
        response.body = null;
      }
      return response;
    }
  },  
})

axios.defaults.withCredentials = true

axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const authService = getInstance()
        authService.logoutFrontend()
        store.dispatch("resetState")
        return router.push('/login')
    }
  }
  return Promise.reject(error)
})

Vue.use(AuthPlugin, {
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

Vue.use(VueMixpanel, {
  token: "b02952a6166d8b8031cdde208b6bd7fe",
})

Vue.mixin(titleMixin)
Vue.mixin(localeMixin)

Vue.filter('currency', function(amount, decimalCount = 2, decimal = ",", thousands = ".") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
})

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner, faCheckCircle, faExclamationCircle, faCalendarAlt, faClock, faExchangeAlt, faEnvelope, faHeart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faSpinner)
library.add(faCheckCircle)
library.add(faExclamationCircle)
library.add(faCalendarAlt)
library.add(faClock)
library.add(faExchangeAlt)
library.add(faEnvelope)
library.add(faHeart)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Vue select
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect);

Vue.config.productionTip = false
new Vue({
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount('#app')